document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollSmoother, ScrollTrigger, ScrollToPlugin);

  // reveal element GS_REVEAL class, used in IPO section and message from leadership
  gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
    
    gsap.set(elem, { autoAlpha: 0 }); // Hide the element initially
    // Calculate x and y values outside the function call
    let x = 0,
      y = 100;

    // Check if the screen is in mobile view
    let isMobileView = window.innerWidth <= 768;
    //let isTabletView = window.innerWidth > 768 && window.innerWidth <= 1024;

    if (isMobileView) {
      // Change animation direction for mobile view
      x = 0;
      y = 100;
    } else if (!isMobileView) {
      
      // Proceed with animation only for non-mobile views

        // Calculate x and y values for tablet and larger screens
        if (elem.classList.contains("gs_reveal_fromLeft")) {
          x = -100;
          y = 0;
        } else if (elem.classList.contains("gs_reveal_fromRight")) {
          x = 100;
          y = 0;
        }

    }

      let duration = 1.25; // Default duration if not provided
      let dataDuration = elem.getAttribute("data-duration");
      if (dataDuration) {
        duration = parseFloat(dataDuration);
      }

      let delay = 0; // Default delay if not provided
      let dataDelay = elem.getAttribute("data-delay");
      if (dataDelay) {
        delay = parseFloat(dataDelay);
      }

      ScrollTrigger.create({
        trigger: elem,
        start: "clamp(top bottom)",
        id: "textReveal",
        onEnter: function () {
          animateFrom(elem, x, y, duration, delay);
        },
      });

   
  
  });

  //############################ end reveal ############################

  const cards = gsap.utils.toArray(".card-item");
  const spacer = 20;
  const minScale = 0.8;
  const distributor = gsap.utils.distribute({ base: minScale, amount: 0.2 });

  const gsapMM = gsap.matchMedia();

  //desktop animations for gsap
  gsapMM.add("(min-width: 1280px)", () => {

     ScrollSmoother.create({
    content:"main",
    smooth: 1,
    effects: true,
    smoothTouch: false,
    normalizeScroll: false
  });

  //start bg fold 
    ScrollTrigger.create({
      trigger: ".bg",
      start: "clamp(top top)",
      end: "clamp(bottom 0)",
      scrub: 1,
      pin: true,
      pinSpacing: false,
     // markers:true,
      id:"vector",
    });

    ScrollTrigger.create({
      trigger: "#title-high",
      start: "clamp(top top)",
      end: "bottom 250px",
      scrub: 1,
      endTrigger: ".cards:last-child",
      //markers: true,
      pin: true,
      pinSpacing: false,
      id: "title",
    });
    //end title cards sticky

    cards.forEach((card, index) => {
      //const scaleVal = distributor(index, cards[index], cards);
      const tween = gsap.to(card, {
        scrollTrigger: {
          trigger: card,
          start: "clamp(top top)",
          id: "cards",
          //start: "clamp(top center)",
          scrub: true,
          //markers: true,
          invalidateOnRefresh: true,
          onComplete: () => ScrollTrigger.refresh(), // test comment out aug10
          //onComplete: cardComplete(),
        },
        ease: "none",
        //scale: scaleVal,
        //scale: () => 1 - (cards.length - index) * 0.025,
        //onComplete: () => ScrollTrigger.refresh(),
      });

      ScrollTrigger.create({
        trigger: card,
        start: `top-=${index * spacer} top`,
        endTrigger: ".cards",
        end: `bottom top+=${200 + cards.length * spacer}`,
        pin: true,
        pinSpacing: false,
        //markers: true,
        id: "pin",
        invalidateOnRefresh: true,
        onComplete: () => ScrollTrigger.refresh(),
        //onToggle: self => console.log("toggled, isActive:", index),
      });
     // console.log(`top-=${index * spacer} top`)
    }); //end for each

  
});
  //end desktop gsap config

  gsapMM.add("(max-width: 768px)", () => {
    ScrollTrigger.create({
      trigger: "#title-high",
      start: "clamp(top top)",
      end: "clamp(bottom 150px)",
      scrub: 1,
      endTrigger: ".cards:last-child",
      id: "title",
      //markers: true,
      pin: true,
      pinSpacing: false,
      id: "title",
    });
    //end title cards sticky for mobile

    cards.forEach((card, index) => {
        //const scaleValMob = distributor(index, cards[index], cards);
    
        const tweenMob = gsap.to(card, {
          scrollTrigger: {
            trigger: card,
            start: "clamp(top center)",
            //start: "top center",
            scrub: true,
           // markers: true,
            invalidateOnRefresh: true,
          },
          ease: "none",
          //scale: scaleVal,
          //scale: () => scaleVal
        });
    
        ScrollTrigger.create({
          trigger: card,
          //start: `top-=${index * spacer} top`,
          start: "clamp(top 22%)",
          endTrigger: ".cards",
          //end: `bottom top+=${200 + (cards.length * spacer)}`,
          end: "clamp(bottom 20%)",
          pin: true,
          pinSpacing: false,
          //markers: true,
          id: "stackCards",
          invalidateOnRefresh: true,
          onComplete: () => ScrollTrigger.refresh(),
        });
      }); //end for each, card stacking on mobile
  }); //end mobile gsap config

  

  // nav links

  const links = gsap.utils.toArray("#nav > li a");
  links.forEach((abc) => {
    let element = document.querySelector(abc.getAttribute("href")),
      linkST = ScrollTrigger.create({
        trigger: element,
        start: "top top",
      });
    ScrollTrigger.create({
      trigger: element,
      start: "clamp(top center)",
      end: "clamp(bottom center)",
      //onToggle: self => self.isActive && setActive(a)
    });

    abc.addEventListener("click", (ev) => {
      ev.preventDefault();
      gsap.to(window, {
        duration: 1,
        scrollTo: linkST.start,
        overwrite: "auto",
      });
    });
  });
}); //end  DOMContentLoaded

function animateFrom(elem, x, y, duration, delay) {
  gsap.fromTo(
    elem,
    { x, y, autoAlpha: 0 },
    {
      duration: duration,
      delay: delay,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "power4.out",
      overwrite: "auto",
      stagger: 0.1,
    },
  );
} // End of animateFrom function
